// React
import { useState } from 'react'

// React bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styled components
import styled, { keyframes } from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Animated
import { Animated } from "react-animated-css";

// Components
import SectionHeading from './headings/SectionHeading'
import FounderProfileModal from './modal/founderProfileModal'

// Helpers
import fontSize from '../helpers/fontSize'

// Images
import Albert from '../assets/images/our_team/Cloudxier-ALBERT-Profile.png'
import Meilisa from '../assets/images/our_team/Cloudxier-MEILISA-Profile.png'

// Social Media Images
import LinkedIn from '../assets/images/icons/linkedin_light.png'

function OurFounders({
  fromLandingPage
}) {

  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 991px)' })
  const largeScreen = useMediaQuery({ query: '(max-width: 1500px)' })

  const [isContentVisible, setContentVisible] = useState(false)

  const [founderTeams, setFounderTeams] = useState([
    {
      imageUrl: Albert,
      name: 'Albert Agung Arditya',
      position: 'CEO & Co-Founder',
      linkedInUrl: 'https://www.linkedin.com/in/albert-agung-arditya/',
      Specialization: [
        'React JS', 'React Native', 'Jquery', 'Node JS', 'Sequelize', 'Express JS', 'PostgreSQL', 'MongoDB', 'NoSQL', 'MongoDB Atlas', 'Google Cloud Compute Engine', 'Google Cloud Bucket', 'Google Cloud CDN', 'Amazon EC2', 'Amazon S3', 'IT Project Management'
      ],
    },
    {
      imageUrl: Meilisa,
      name: 'Meilisa',
      position: 'Co-Founder & Principal of Product',
      linkedInUrl: 'https://www.linkedin.com/in/meilisasanjaya/',
      Specialization: [
        'UI/UX Design', 'Mobile App dan Web Design', 'IT Product Management', 'Project Management', 'Graphic Design'
      ],
    }
  ])
  const [founderData, setFounderData] = useState([
    {
      name: 'Albert Agung Arditya',
      position: 'CEO & Co-Founder',
      Education: [
        {
          title: 'Diploma in Project Management',
          description: 'Interlink Technology Australia, 2021'
        },
        {
          title: 'Full-stack Engineering Bootcamp',
          description: 'Hacktiv8 Coding Bootcamp, Indonesia'
        },
        {
          title: 'Faculty of Economics major in Enterpreneurship',
          description: 'Prasetya Mulya University, Indonesia'
        }
      ],
      Experience: [
        '4+ years experience as Head of Technical Support Engineer at MM-Sustainibility Trisakti University',
        'Have specialisation in Mobile App Development, Software Development, Enterprise Custom Software Development, Website Development, Cloud Computing, and API Creation and Management',
        'Former CTO of Shooper App (Retail-tech consumer analytics app)'
      ],
      Profile: <div>
        <p>Albert has over 7+ years experience as a software engineer, he has cultivated a diverse skill set that empowers to tackle complex tech challenges and deliver impactful solutions.</p>
        <p>Prior to co-founding Cloudxier, Albert was the Chief Technology Officer at one of retail-tech consumer analytics startup, he demonstrated his expertise by providing invaluable software engineering and technical architecture advices. His strategic insights and hands-on approach enables the implementation of cutting-edge solutions, fostering innovation and driving sustainable growth.</p>
        <p>With his proven track records in scalable software development and cross-functional team collaborations, his proficiency extends across various domains of software engineering, allowing him to navigate complex landscapes with confidence. Albert continues to make a significant impact in the tech industry, where he applies his expertise to revolutionize the future of technology.</p>
        <p>Currently, he is also a Business Operations Manager at Institute Sustainability and Agility (ISA) which is a leading in ESG & Sustainability consulting firm. He holds education background in Engineering from Hacktiv8 Coding Bootcamp Indonesia, as well as majoring Entrepreneurship in Faculty of Economics - Prasetya Mulya University. Albert also holds a Diploma in Project Management from Interlink Technology Australia.</p>
      </div>
    },
    {
      name: 'Meilisa',
      position: 'Co-Founder & Principal of Product',
      Education: [
        {
          title: 'Diploma in Project Management',
          description: 'Interlink Technology Australia, 2021'
        },
        {
          title: 'Certified in Digital Product Management',
          description: 'University of Virginia, Darden School of Business, 2021'
        },
        {
          title: 'Diploma in Graphic Design',
          description: 'Nanyang Academy of Fine Arts, Singapore, 2009-2012'
        },
      ],
      Experience: [
        '8+ years experience in Visual Design, UI/UX Design',
        'Creative and Design Team for Sustainibility Report, Danone Indonesia, PT Saratoga Investama Sedaya Tbk, etc..',
        'Event Lead Coordinator for ISTR 10th Asia Pasific Regional Conference and PhD Seminar (Jakarta, 2017)',
        'Creative Manager as CECT & MM-Sustainibility Trisakti University',
        '5+ years experience as Senior Event Lead at CECT Sustainability Awards www.cectsustainabilityawards.com',
      ],
      Profile: <div>
        <p>Together with Albert, Meilisa co-founded Cloudxier in 2018, she has specialisation in product and design industry for more than 12 years. Her journey began in Visual Designs, where she honed her skills in crafting captivating and intuitive interfaces. Over the years, she transitioned into Product and UI/UX design, specializing in creating seamless experiences and software development planning for B2B clients.</p>
        <p>Prior to Cloudxier, Meilisa served as a creative head in a prominent consulting firm, where she led various projects related to research and education events, publications and involved as creative team in designing sustainability reports. Her leadership and creative minds are instrumental in delivering impactful designs for clients spanning diverse sectors, including investment, F&B companies, agriculture and beyond.</p>
        <p>Meilisa's unique blend of creativity and strategic thinking allows her to effectively translate client needs into compelling design solutions. Her forte lies in conducting meticulous planning for software development and mobile applications, ensuring alignment with business objectives and user-centric approach. With a commitment to excellence, Meilisa continues to drive Cloudxier forward, shaping the future of digital experiences and empowering businesses to thrive in a dynamic market landscape.</p>
        <p>Meilisa holds an education from Nanyang Academy of Fine Arts-Singapore, majoring in Graphic Design and earned a Digital Product Management certification from University of Virginia (UVA) Darden School of Business. She also earned a Diploma in Project Management from Interlink Technology Australia. Currently, he is also a Managing Partner at Institute Sustainability and Agility (ISA) which is a leading in ESG & Sustainability consulting firm.</p>
      </div>
    }
  ])

  const [filteredFounderData, setFilteredFounderData] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [idModalBody, setIdModalBody] = useState('modal-body')
  const [idModalContent, setIdModalContent] = useState('modal-content')
  const [findIdModal, setFindIdModal] = useState('')

  const showPopModalProfile = (props) => {
    setShowModal(true)
    const filteredData = founderData && founderData.filter((data) => data.name === props.name)
    setFilteredFounderData(filteredData[0])
  }

  function closePopModalProfile() {
    setShowModal(false)
    setFilteredFounderData([])
  }

  return (
    <MainContainer fromLandingPage={fromLandingPage} smallScreen={smallScreen} mediumScreen={mediumScreen} largeScreen={largeScreen}>
      <VisibilitySensor onChange={(isVisible) => setContentVisible(isVisible)} active={!isContentVisible} partialVisibility={true}>
        <Animated animationIn="fadeInUp" animationInDuration={2000} isVisible={isContentVisible}>
          <SectionHeading
            title='The Founders'
            subtitle='' />
          <ContentContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              {
                founderTeams && founderTeams.map((team, index) => {
                  return (
                    <Col sm={12} md={6} lg={6} key={index} style={{ padding: smallScreen ? '10px 10px 20px 10px' : mediumScreen ? '10px 20px' : "20px 25px" }}>
                      <TeamContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                        <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen} largeScreen={largeScreen}>
                          <Image src={team.imageUrl} smallScreen={smallScreen} mediumScreen={mediumScreen} className={team.name} onClick={() => showPopModalProfile({ name: team.name })} />
                        </ImageContainer>
                        <DetailContainer smallScreen={smallScreen} mediumScreen={mediumScreen} largeScreen={largeScreen} onClick={() => showPopModalProfile({ name: team.name })}>
                          <Name className={team.name} smallScreen={smallScreen} mediumScreen={mediumScreen}>
                            {team.name}
                          </Name>
                          <Position smallScreen={smallScreen} mediumScreen={mediumScreen}>
                            {team.position}
                          </Position>
                        </DetailContainer>
                        <SkillContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                          <HeadingSkill smallScreen={smallScreen} mediumScreen={mediumScreen}>
                            Expertise and specialization
                          </HeadingSkill>
                          <DetailSkill smallScreen={smallScreen} mediumScreen={mediumScreen}>
                            {
                              team.Specialization.join(', ')
                            }
                          </DetailSkill>
                        </SkillContainer>
                        {/* <ImageContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                        <LinkedInImage smallScreen={smallScreen} mediumScreen={mediumScreen} src={LinkedIn} onClick={() => window.open(team.linkedInUrl)} />
                      </ImageContainer> */}
                      </TeamContainer>
                    </Col>
                  )
                })
              }
              {
                showModal === true ? (
                  <FounderProfileModal
                    founderData={filteredFounderData}
                    closePopModalProfile={closePopModalProfile}
                    idModalBody={idModalBody}
                    idModalContent={idModalContent}
                    setFindIdModal={setFindIdModal}
                  />
                ) : ""
              }
            </Row>
          </ContentContainer>
        </Animated>
      </VisibilitySensor>
    </MainContainer>
  )
}

export default OurFounders

const MainContainer = styled.div`
  padding: ${({ fromLandingPage, mediumScreen, smallScreen, largeScreen }) =>
    fromLandingPage && smallScreen ? '80px 10vw 40px 10vw' :
      fromLandingPage && mediumScreen ? '80px 10vw 40px 10vw' :
        fromLandingPage && largeScreen ? '90px 13vw 40px 13vw' :
          fromLandingPage ? '90px 17vw 40px 17vw' : '0px 8vw 40px 8vw'
  }
`
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F3F3F3;
  border-radius: 5px;
  align-items: center;
  padding: ${({ smallScreen, mediumScreen }) => smallScreen ? '5vw 40px' : mediumScreen ? '5vw 40px' : '5vw 40px 50px 40px'};
`
const ImageContainer = styled.div`
  position: ${({ smallScreen, mediumScreen }) => smallScreen ? 'absolute' : mediumScreen ? 'absolute' : 'absolute'};
  top: ${({ smallScreen, mediumScreen, largeScreen }) => smallScreen ? '25px' : mediumScreen ? '25px' : largeScreen ? '35px' : '35px'};
  left: ${({ smallScreen, mediumScreen, largeScreen }) => smallScreen ? '60px' : mediumScreen ? '60px' : largeScreen ? '60px' : '70px'};
  right: 0px;
`
const Image = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '175px' : mediumScreen ? '200px' : '225px'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '175px' : mediumScreen ? '200px' : '225px'};
  border-radius: 100%;
  object-fit: cover;
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '15px' : '20px'};
  &: hover {
    cursor: ${({ className }) => className === 'Albert Agung Arditya' || className === 'Meilisa' ? 'pointer' : 'auto'}
  };
  border: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px solid #F3F3F3' : mediumScreen ? '10px solid #F3F3F3' : '10px solid #F3F3F3'};
`
const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: ${({ mediumScreen, smallScreen }) => smallScreen ? '100%' : mediumScreen ? '100%' : '100%'};
  margin-top: ${({ smallScreen, mediumScreen, largeScreen }) => smallScreen ? '145px' : mediumScreen ? '155px' : largeScreen ? '165px' : '135px'};
  padding:  ${({ smallScreen, mediumScreen, largeScreen }) => smallScreen ? '3.5rem 20px 15px 20px' : mediumScreen ? '3.5rem 25px 20px 25px' : largeScreen ? '3.5rem 30px 25px 30px' : '60px 30px 25px 30px'};
  text-align: left;
  border-radius: 20px;
`
const Name = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '17px' : mediumScreen ? '22px' : "27px"};
  font-weight: bold;
  text-align: left;
  color: #336AFE;
  &: hover {
    cursor: ${({ className }) => className === 'Albert Agung Arditya' || className === 'Meilisa' ? 'pointer' : 'auto'}
  }
`
const Position = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '21px' : "26px"};
  text-align: left;
  color: #336AFE;
`
const LinkedInImage = styled.img`
  width: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '20px' : '40px'};
  height: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px' : mediumScreen ? '20px' : '40px'};
  border-radius: 100%;
  object-fit: cover;
  margin-top: ${({ smallScreen, mediumScreen }) => smallScreen ? '-25px' : mediumScreen ? '-25px' : '10px'};
  cursor: pointer;
`
const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ smallScreen, mediumScreen }) => smallScreen ? '20px 0 5px 0' : mediumScreen ? '25px 0 5px 0' : "30px 0 0 0"};
  max-height: ${({ smallScreen, mediumScreen }) => smallScreen ? 'unset' : mediumScreen ? '125px' : "125px"}; 
  overflow-y: ${({ smallScreen, mediumScreen }) => smallScreen ? 'unset' : mediumScreen ? 'auto' : "auto"}; 
`
const HeadingSkill = styled.p`
  color: #336AFE;
  font-weight: 700;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '19px' : "22px"};
`
const DetailSkill = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '14px' : mediumScreen ? '16px' : "18px"};
  text-align: justify;
`