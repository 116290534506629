// React
import { useState } from 'react'

// React bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styled components
import styled, { keyframes } from 'styled-components'

// React responsive
import { useMediaQuery } from 'react-responsive'

// React visibility sensor
import VisibilitySensor from 'react-visibility-sensor'

// Animated
import { Animated } from "react-animated-css";

// Components
import SectionHeading from './headings/SectionHeading'

function InternTestimonials() {

  const extraSmallScreen = useMediaQuery({ query: '(max-width: 500px)' })
  const smallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const mediumScreen = useMediaQuery({ query: '(max-width: 991px)' })
  const mediumLargeScreen = useMediaQuery({ query: '(max-width: 1280px)' })
  const largeScreen = useMediaQuery({ query: '(max-width: 1500px)' })

  const [isContentVisible, setContentVisible] = useState(false)

  const [internTestimony, setInternTestimony] = useState([
    {
      image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/6dccca2f42a8049f4e0ec9cf7e0dfe6a3d051717c3d1d7a9be9474dcfc4ec8cd965836ce40ba5ed300010ee974937284feb57a63090c5d9a0ac3bcf5186bbc2a909d9fd9aab5c9dd0c8e89af2d865d5295064ef084ff676633c7443401499261c3598b0f826832c8246cc120b9948717ac72a97d896eb55543c8/rez-file-1740725762887.png',
      name: 'Sofia Aramis',
      school: 'Student of University of Western Australia, major in Finance and Marketing',
      description: <p>Internship programme in collaboration with <a href="https://www.acicis.edu.au/" target="_blank" rel="noopener noreferrer">ACICIS Indonesia</a></p>,
      testimony: <>
        <p>"My 4-week internship at Cloudxier was an invaluable experience that deepened my understanding of IT and sustainability in the Indonesian business sector. From day one, the Cloudxier and ISA teams provided a welcoming and supportive environment, allowing me to develop skills in website content planning, social media strategy, and pricing model research."</p>
        <p>"This internship challenged me to think critically, adapt quickly, and improve my problem-solving abilities. It was inspiring to see Cloudxier’s commitment to innovation and sustainability, and I leave with a greater appreciation for how technology can drive positive environmental impact. I am grateful for this opportunity and look forward to applying these insights to future endeavors."</p>
      </>
    }
  ])

  return (
    <VisibilitySensor onChange={(isVisible) => setContentVisible(isVisible)} active={!isContentVisible} partialVisibility={true}>
      <Animated animationIn="slideInLeft" animationInDuration={2000} isVisible={isContentVisible}>
        <SectionHeading
          title='Internship Testimonial'
          subtitle='' />
        <InternTestimonyList smallScreen={smallScreen} mediumScreen={mediumScreen} mediumLargeScreen={mediumLargeScreen} largeScreen={largeScreen}>
          {
            internTestimony && internTestimony.map((intern, index) => {
              return (
                <DetailTestimonyContainer smallScreen={smallScreen} mediumScreen={mediumScreen}>
                  <InternProfileContainer extraSmallScreen={extraSmallScreen} smallScreen={smallScreen} mediumScreen={mediumScreen} largeScreen={largeScreen}>
                    <ImageContainer extraSmallScreen={extraSmallScreen} smallScreen={smallScreen} mediumScreen={mediumScreen}>
                      <Image src={intern.image_url} extraSmallScreen={extraSmallScreen} smallScreen={smallScreen} mediumScreen={mediumScreen} />
                    </ImageContainer>
                    <UserDetailContainer extraSmallScreen={extraSmallScreen} smallScreen={smallScreen} mediumScreen={mediumScreen} largeScreen={largeScreen}>
                      <Name smallScreen={smallScreen} mediumScreen={mediumScreen}>
                        {intern.name}
                      </Name>
                      <School smallScreen={smallScreen} mediumScreen={mediumScreen}>
                        {intern.school}
                      </School>
                      <Description smallScreen={smallScreen} mediumScreen={mediumScreen}>
                        {intern.description}
                      </Description>
                    </UserDetailContainer>
                  </InternProfileContainer>
                  <InternTestimony smallScreen={smallScreen} mediumScreen={mediumScreen} largeScreen={largeScreen}>
                    {intern.testimony}
                  </InternTestimony>
                </DetailTestimonyContainer>

              )
            })
          }
        </InternTestimonyList>
      </Animated>
    </VisibilitySensor>
  )
}

export default InternTestimonials

const InternTestimonyList = styled.div`
  width: ${({ mediumScreen, mediumLargeScreen, largeScreen }) => mediumScreen ? '80%' : mediumLargeScreen ? '85%' : largeScreen ? '70%' : "60%"}; 
  margin-left: auto; 
  margin-right: auto; 
  margin-bottom: 50px; 
`
const DetailTestimonyContainer = styled.div`
  display: flex;
  flex-direction: ${({ mediumScreen }) => mediumScreen ? 'column-reverse' : 'row'};
  align-items: center;
  justify-content: center;
  background-color: #F3F3F3;
  border-radius: 15px;
`
const InternProfileContainer = styled.div`
  display: flex;
  flex-direction: ${({ extraSmallScreen, mediumScreen }) => extraSmallScreen ? 'column' : mediumScreen ? 'row' : 'column'};
  justify-content: center;
  gap: 20px;
  padding: 25px;
  background-color: #F3F3F3;
  border-radius: ${({ mediumScreen }) => mediumScreen ? '0px 0px 15px 15px' : '15px 0px 0px 15px'};
  flex-wrap: ${({ mediumScreen }) => mediumScreen ? 'none' : 'wrap'};
  flex-grow: 3;
`
const ImageContainer = styled.div`
  width: ${({ extraSmallScreen, mediumScreen }) => extraSmallScreen ? '100%' : mediumScreen ? '25%' : '50%'}; 
  display: flex;
  align-items: center;
  justify-content: ${({ mediumScreen }) => mediumScreen ? 'center' : 'start'};
`
const Image = styled.img`
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  width: ${({ extraSmallScreen }) => extraSmallScreen ? '30%' : '100%'};
`
const UserDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  text-align: left;
  border-radius: 20px;
  padding: 15px;
  gap: 5px;
  width: ${({ extraSmallScreen, mediumScreen }) => extraSmallScreen ? '100%' : mediumScreen ? '75%' : '100%'};
`
const Name = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '16px' : mediumScreen ? '20px' : "22px"};
  font-weight: bold;
  text-align: left;
  color: #336AFE;
`
const School = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '11px' : mediumScreen ? '13px' : "15px"};
  text-align: left;
  color: #336AFE;
`
const Description = styled.div`
  color: #336AFE;
  font-weight: 700;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '10px' : mediumScreen ? '12px' : "14px"};
`
const InternTestimony = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-size: ${({ smallScreen, mediumScreen }) => smallScreen ? '12px' : mediumScreen ? '14px' : "16px"};
  text-align: justify;
  line-height: ${({ mediumScreen }) => mediumScreen ? '1.6rem' : "2.1rem"};
  padding: 25px;
  background-color: #7D7D7D;
  border-radius: ${({ mediumScreen }) => mediumScreen ? '15px 15px 0px 0px' : "0px 15px 15px 0px"};
  flex-wrap: wrap;
  flex-shrink: 2;
  font-weight: bold;
`