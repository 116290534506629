// React
import { useEffect } from 'react'

// Components
import HeadMeta from '../components/HeadMeta'
import HomeMainBanner from '../components/banners/HomeMainBanner'
import WayOfDevelopment from '../components/FeatureCard'
import HomeTestimonials from '../components/HomeTestimonials'
import CaseStudies from '../components/CaseStudies'
import HomeClients from '../components/HomeClients'
import DiscussWithUs from '../components/banners/ActionBanner'
import OurFounders from '../components/OurFounders'
import HomeTechStacks from '../components/HomeTechStacks'
import HomeSliderBanner from '../components/HomeSliderBanner'
import HomeFeaturedOn from '../components/HomeFeaturedOn'
import HomeOurServices from '../components/HomeOurServices'
import HomeBlogPost from '../components/HomeBlogPost'
import WhyChooseUs from '../components/WhyChooseUs'
import InternTestimonials from '../components/InternTestimonials'

// Images
import CloudxierLogoSquare from '../assets/images/logo/Cloudxier-logofull-colored-05.png'

function Home() {
  // Scroll window to top on load
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeadMeta
        title="CLOUDXIER - IT, Software, Website, Mobile App Development - Jakarta, Indonesia"
        description="Cloudxier - We provide IT solutions for your business"
        siteName="Cloudxier"
        url="https://www.cloudxier.com/"
        image={CloudxierLogoSquare}
      />
      <HomeMainBanner />
      <HomeOurServices />\
      <WhyChooseUs fromLandingPage={true} />
      <HomeSliderBanner />
      <HomeTechStacks />
      <hr style={{ height: "2px", border: "none", color: "#333", backgroundColor: "#333" }} />
      <HomeClients />
      <WayOfDevelopment />
      <OurFounders fromLandingPage={true} />
      <hr style={{ marginBottom: "70px", height: "2px", border: "none", color: "#333", backgroundColor: "#333" }} />
      <InternTestimonials />
      <CaseStudies />
      <HomeTestimonials />
      <HomeBlogPost />
      <HomeFeaturedOn />
      <DiscussWithUs />
    </div>
  )

}

export default Home